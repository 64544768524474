export enum UserRole {
  ADMIN = 'ADMIN',
  VIRTUAL_ASSISTANT = 'VIRTUAL_ASSISTANT',
  BROKER = 'BROKER',
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}

export enum PermissionType {
  LISTING_ALL = 'LISTING_ALL',
  LISTING_ZIP = 'LISTING_ZIP',
  LISTING_OWN = 'LISTING_OWN',
  USERS = 'USERS',
  ADMIN = 'ADMIN',
  TASKS = 'TASKS',
}

export type Permission = {
    key: PermissionType;
    read: boolean;
    write: boolean;
    delete: boolean;

}

export type Role = {
  key: UserRole;
  permissions: Permission[];
  createdAt: Date;
  updatedAt: Date;
}

export type User = {
    email: string;
    id: string;
    phone_number?: string;
    sso?: string;
    firstName?: string;
    lastName?: string;
    picture?: string;
    roles?: Role[]
};
